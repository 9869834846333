import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest, retry, select, SagaGenerator } from 'typed-redux-saga';
import { isEqual } from 'lodash-es';
import { IDependencies } from '../../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  IGetInvitedUserListLoading,
  IGetUserListOperatorRequestAction,
  IGetUserListRequestAction,
  UserListActions,
  IGetUserListLoading,
  IGetUserListSuccessAction,
  IPollingInvitedUserListRequestAction,
} from '../../user-list/state/userListActions';
import { NotificationDeleteOperatorResponse } from '../../interfaces/Operator.types';
import { PollInviteUserOperation, UserDeleteResponse, UserListResponse } from '../../interfaces/User.types';
import { UserListSelectors } from '../../user-list/state/UserListSelectors';
import {
  UserModalsActions,
  IDeleteOperatorErrorAction,
  IDeleteOperatorModalIsLoadingAction,
  IDeleteOperatorModalIsNotLoadingAction,
  IDeleteOperatorRequestAction,
  IDeleteOperatorSuccessAction,
  IHideDeleteOperatorModalAction,
} from './userModalsActions';
import {
  IDeleteInvitedUserErrorAction,
  IDeleteInvitedUserRequestAction,
  IDeleteInvitedUserSuccessAction,
  IDeleteUserErrorAction,
  IDeleteUserModalIsLoadingAction,
  IDeleteUserModalIsNotLoadingAction,
  IDeleteUserRequestAction,
  IDeleteUserSuccessAction,
  IHideDeleteUserModalAction,
} from './userModalsActions.type';
import { userModalsActions } from './userModalsSlice';
import { Optional } from 'lib/types/Optional';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { InfiniteScrollConstants, POLL_INTERVAL, POLL_MAX_RETRIES } from 'config/constants';
import { Status } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export function* deleteOperatorSaga(
  action: IDeleteOperatorRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<NotificationDeleteOperatorResponse> | void>
  | PutEffect<IDeleteOperatorModalIsLoadingAction>
  | PutEffect<IGetUserListOperatorRequestAction>
  | PutEffect<IDeleteOperatorSuccessAction>
  | PutEffect<IDeleteOperatorErrorAction>
  | PutEffect<IDeleteOperatorModalIsNotLoadingAction>
  | PutEffect<IHideDeleteOperatorModalAction>,
  void,
  IDependencies
> {
  const { operatorService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  yield* put(UserModalsActions.deleteOperatorModalIsLoading());

  try {
    const response = yield* call(operatorService.delete, action.payload);

    if (response?.notificationDeleteOperator?.data) {
      yield* call(toastService.success, {
        message: t('userList.operator.deleteModal.toasts.success.message'),
        description: t('userList.operator.deleteModal.toasts.success.description'),
      });

      yield* put(
        UserListActions.getUserListOperatorRequest({
          paginationOptions: {
            limit: InfiniteScrollConstants.MAX_ITEMS,
            paginationToken: '',
          },
        })
      );
    }

    yield* put(UserModalsActions.deleteOperatorSuccess(response));
    yield* put(DrawersActions.hideOperatorDetailsDrawer());
  } catch (error) {
    console.error(error);
    yield* call(toastService.error, {
      message: t('userList.operator.deleteModal.toasts.error.message'),
      description: (error as Error).message,
    });

    yield* put(
      UserModalsActions.deleteOperatorError({
        error,
      })
    );
  } finally {
    yield* put(UserModalsActions.hideDeleteOperatorModal());
    yield* put(UserModalsActions.deleteOperatorModalIsNotLoading());
  }
}

export function* deleteUserSaga(
  action: IDeleteUserRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<UserDeleteResponse> | void>
  | PutEffect<IDeleteUserModalIsLoadingAction>
  | PutEffect<IDeleteUserRequestAction>
  | PutEffect<IDeleteUserSuccessAction>
  | PutEffect<IDeleteUserErrorAction>
  | PutEffect<IDeleteUserModalIsNotLoadingAction>
  | PutEffect<IHideDeleteUserModalAction>
  | PutEffect<IGetUserListLoading>
  | PutEffect<IGetUserListSuccessAction>
  | SagaGenerator<UserListResponse, CallEffect<UserListResponse>>
  | PutEffect<IGetUserListRequestAction>,
  void,
  UserListResponse
> {
  const { userService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  yield* put(userModalsActions.deleteUserModalIsLoading());
  try {
    const response = yield* call(userService.delete, action.payload);
    if (response?.userDeleteByEmail?.data) {
      yield* call(toastService.success, {
        message: t('userList.deleteUserModal.toasts.success.message'),
        description: t('userList.deleteUserModal.toasts.success.description'),
      });
    }

    yield* put(userModalsActions.deleteUserSuccess(response));
    yield* put(userModalsActions.hideDeleteUserModal());

    // polling for fetch new data

    const oldData = yield* select(UserListSelectors.selectActiveUserData);
    const getUser = async (): Promise<UserListResponse> => {
      const newData = await userService.list({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
        },
        filter: {
          status: Status.Active,
        },
      });

      const isEqualObjects = isEqual(oldData, newData?.users?.data);
      if (isEqualObjects) {
        throw new Error('No new data!');
      }
      return newData;
    };

    yield* put(UserListActions.getUserListLoading());
    const r: UserListResponse = yield retry(POLL_MAX_RETRIES, POLL_INTERVAL + 1000, getUser);

    yield* put(UserListActions.getUserListSuccess(r));
  } catch (error: Error | unknown) {
    if (error instanceof Error) {
      yield* call(toastService.error, {
        message: t('userList.deleteUserModal.toasts.error.message'),
        description: error.message,
      });
    }

    yield* put(
      userModalsActions.deleteUserError({
        error,
      })
    );
  } finally {
    yield* put(userModalsActions.hideDeleteUserModal());
    yield* put(userModalsActions.deleteUserModalIsNotLoading());
  }
}

export function* deleteInvitedUserSaga(
  action: IDeleteInvitedUserRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<UserDeleteResponse> | void>
  | PutEffect<IPollingInvitedUserListRequestAction>
  | PutEffect<IDeleteInvitedUserRequestAction>
  | PutEffect<IDeleteInvitedUserSuccessAction>
  | PutEffect<IDeleteInvitedUserErrorAction>
  | PutEffect<IGetInvitedUserListLoading>,
  void,
  UserListResponse
> {
  const { userService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.delete, action.payload);
    if (response?.userDeleteByEmail?.data) {
      yield* call(toastService.success, {
        message: t('userList.deleteInvitation.toasts.success.message'),
        description: t('userList.deleteInvitation.toasts.success.description'),
      });
    }
    yield* put(userModalsActions.deleteInvitedSuccess({ response, email: action.payload.input.email }));

    yield* put(
      UserListActions.pollingInvitedUserListRequest({
        email: action.payload.input.email,
        operation: PollInviteUserOperation.DELETED,
      })
    );
  } catch (error: Error | unknown) {
    if (error instanceof Error) {
      yield* call(toastService.error, {
        message: t('userList.deleteInvitation.toasts.error.message'),
        description: error.message,
      });
    }

    yield* put(
      userModalsActions.deleteInvitedError({
        error,
        email: action.payload.input.email,
      })
    );
  }
}

export function* userModalsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(UserModalsActions.DELETE_OPERATOR_REQUEST, deleteOperatorSaga);
  yield* takeLatest(userModalsActions.deleteUserRequest, deleteUserSaga);
  yield* takeLatest(userModalsActions.deleteInvitedRequest, deleteInvitedUserSaga);
}
