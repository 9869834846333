import {
  ResponseUserUpdate,
  ResponseUsersList,
  Role,
  ResponseUserInvite,
  ResponseBoolean,
} from '../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';

export { Role };

export interface UserListResponse {
  users: ResponseUsersList;
}

export interface UserUpdateResponse {
  data: ResponseUserUpdate;
}

export interface UserInviteResponse {
  userInvite: ResponseUserInvite;
}

export interface UserListAvailableFilters {
  roles: Role[];
}

export interface UserDeleteResponse {
  userDeleteByEmail: ResponseBoolean;
}

export enum PollInviteUserOperation {
  INVITED = 'INVITED',
  DELETED = 'DELETED',
}
