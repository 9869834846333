import { Action } from 'redux';
import { Optional } from '../../../../../lib/types/Optional';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import {
  PollInviteUserOperation,
  UserInviteResponse,
  UserListAvailableFilters,
  UserListResponse,
  UserUpdateResponse,
} from '../../interfaces/User.types';
import { UserClientInviteOptions, UserClientListOptions, UserClientUpdateOptions } from '../../UserClient';
import { OperatorClientListOptions } from '../../OperatorClient';
import { GetOperatorResponse, NotificationOperatorsResponse, OperatorResponse } from '../../interfaces/Operator.types';
import { SiteServiceListOptions } from 'app/modules/site-management/SiteService';
import { SiteList } from 'app/modules/site-management/interfaces/Site.types';
import { MutationNotificationUpsertOperatorArgs } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MachineServiceListOptions } from 'app/modules/machine-inventory/MachineService';
import { MachineList } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { OpenSearch } from 'config/constants';

// == Active user ==
// GET_USER_LIST
export type IGetUserListRequestOptions = UserClientListOptions;
export type IGetUserListRequestAction = Action<typeof UserListActions.GET_USER_LIST_REQUEST> & {
  payload: UserClientListOptions;
};

export type IGetUserListSuccessOptions = UserListResponse;
export type IGetUserListSuccessAction = Action<typeof UserListActions.GET_USER_LIST_REQUEST> & {
  payload: UserListResponse;
};

export type IGetUserListErrorAction = IErrorAction<typeof UserListActions.GET_USER_LIST_ERROR>;

export type IGetUserListLoading = Action<typeof UserListActions.GET_USER_LIST_LOADING>;

// GET_USER_LIST_MORE_DATA
export type IGetUserListMoreDataRequestOptions = UserClientListOptions;
export type IGetUserListMoreDataRequestAction = Action<typeof UserListActions.GET_USER_LIST_MORE_DATA_REQUEST> & {
  payload: UserClientListOptions;
};

export type IGetUserListMoreDataSuccessOptions = UserListResponse;
export type IGetUserListMoreDataSuccessAction = Action<typeof UserListActions.GET_USER_LIST_MORE_DATA_REQUEST> & {
  payload: UserListResponse;
};

export type IGetUserListMoreDataErrorAction = IErrorAction<typeof UserListActions.GET_USER_LIST_MORE_DATA_ERROR>;

export type IChangeUserListTablePage = Action<typeof UserListActions.CHANGE_TABLE_PAGE> & {
  payload: {
    page: number;
  };
};

export type IChangeUserListTablePageSize = Action<typeof UserListActions.CHANGE_TABLE_PAGE_SIZE> & {
  payload: {
    pageSize: number;
  };
};

// == Invited user ==
// GET_INVITED_USER_LIST

export type IGetInvitedUserListLoading = Action<typeof UserListActions.GET_INVITE_USER_LIST_LOADING>;
export type IGetInvitedUserListRequestOptions = UserClientListOptions;
export type IGetInvitedUserListRequestAction = Action<typeof UserListActions.GET_INVITED_USER_LIST_REQUEST> & {
  payload: UserClientListOptions;
};

export type IGetInvitedUserListSuccessOptions = UserListResponse;
export type IGetInvitedUserListSuccessAction = Action<typeof UserListActions.GET_INVITED_USER_LIST_REQUEST> & {
  payload: UserListResponse;
};

export type IGetInvitedUserListErrorAction = IErrorAction<typeof UserListActions.GET_INVITED_USER_LIST_ERROR>;

// POLLING_INVITED_USER_LIST

export type IPollingInvitedUserListRequestAction = Action<typeof UserListActions.POLLING_INVITED_USER_LIST_REQUEST> & {
  payload: {
    email: string;
    operation: PollInviteUserOperation;
  };
};

// GET_INVITED_USER_LIST_MORE_DATA
export type IGetInvitedUserListMoreDataRequestOptions = UserClientListOptions;
export type IGetInvitedUserListMoreDataRequestAction = Action<
  typeof UserListActions.GET_INVITED_USER_LIST_MORE_DATA_REQUEST
> & {
  payload: UserClientListOptions;
};

export type IGetInvitedUserListMoreDataSuccessOptions = UserListResponse;
export type IGetInvitedUserListMoreDataSuccessAction = Action<
  typeof UserListActions.GET_INVITED_USER_LIST_MORE_DATA_REQUEST
> & {
  payload: UserListResponse;
};

export type IGetInvitedUserListMoreDataErrorAction = IErrorAction<
  typeof UserListActions.GET_INVITED_USER_LIST_MORE_DATA_ERROR
>;

export type IChangeInvitedUserListTablePage = Action<typeof UserListActions.CHANGE_INVITED_USER_TABLE_PAGE> & {
  payload: {
    page: number;
  };
};

export type IChangeInvitedUserListTablePageSize = Action<typeof UserListActions.CHANGE_INVITED_USER_TABLE_PAGE_SIZE> & {
  payload: {
    pageSize: number;
  };
};

// == filters ==
export type IGetUserListFiltersRequestAction = Action<typeof UserListActions.GET_USER_LIST_FILTERS_REQUEST>;

export type IGetUserListFiltersSuccessOptions = UserListAvailableFilters;
export type IGetUserListFiltersSuccessAction = Action<typeof UserListActions.GET_USER_LIST_FILTERS_REQUEST> & {
  payload: UserListAvailableFilters;
};
export type IGetUserListFiltersErrorAction = IErrorAction<typeof UserListActions.GET_USER_LIST_FILTERS_ERROR>;

export type ISetActivePeriodFilter = Action<typeof UserListActions.SET_ACTIVE_PERIOD_FILTER> & {
  payload: {
    period: {
      startDate: string;
      endDate: string;
    };
  };
};

export type ISetActiveRoleFilter = Action<typeof UserListActions.SET_ACTIVE_ROLE_FILTER> & {
  payload: {
    role: Optional<string>;
  };
};

export type ISetUserRoleRequestOptions = UserClientUpdateOptions;
export type ISetUserRoleRequestAction = Action<typeof UserListActions.SET_USER_ROLE_REQUEST> & {
  payload: UserClientUpdateOptions;
};

export type ISetUserRoleSuccessOptions = Optional<UserUpdateResponse>;
export type ISetUserRoleSuccessAction = Action<typeof UserListActions.SET_USER_ROLE_REQUEST> & {
  payload: Optional<UserUpdateResponse>;
};

export type ISetUserRoleErrorAction = IErrorAction<typeof UserListActions.SET_USER_ROLE_ERROR>;

export type IInviteUserRequestOptions = UserClientInviteOptions;
export type IInviteUserRequestAction = Action<typeof UserListActions.INVITE_USER_REQUEST> & {
  payload: UserClientInviteOptions;
};

export type IInviteUserSuccessOptions = Optional<UserInviteResponse>;
export type IInviteUserSuccessAction = Action<typeof UserListActions.INVITE_USER_REQUEST> & {
  payload: Optional<UserInviteResponse>;
};

export type IInviteUserErrorAction = IErrorAction<typeof UserListActions.INVITE_USER_ERROR>;

// Operators
// GET_USER_LIST_OPERATOR
export type IGetUserListOperatorRequestOptions = OperatorClientListOptions;
export type IGetUserListOperatorRequestAction = Action<typeof UserListActions.GET_USER_LIST_OPERATOR_REQUEST> & {
  payload: OperatorClientListOptions;
};

export type IGetUserListOperatorSuccessOptions = Optional<NotificationOperatorsResponse>;
export type IGetUserListOperatorSuccessAction = Action<typeof UserListActions.GET_USER_LIST_OPERATOR_SUCCESS> & {
  payload: Optional<NotificationOperatorsResponse>;
};

export type IGetUserListOperatorErrorAction = IErrorAction<typeof UserListActions.GET_USER_LIST_OPERATOR_ERROR>;

// GET_USER_LIST_OPERATOR_MORE_DATA
export type IGetUserListOperatorMoreDataRequestOptions = OperatorClientListOptions;
export type IGetUserListOperatorMoreDataRequestAction = Action<
  typeof UserListActions.GET_USER_LIST_OPERATOR_MORE_DATA_REQUEST
> & {
  payload: OperatorClientListOptions;
};

export type IGetUserListOperatorMoreDataSuccessOptions = Optional<NotificationOperatorsResponse>;
export type IGetUserListOperatorMoreDataSuccessAction = Action<
  typeof UserListActions.GET_USER_LIST_OPERATOR_MORE_DATA_SUCCESS
> & {
  payload: Optional<NotificationOperatorsResponse>;
};

export type IGetUserListOperatorMoreDataErrorAction = IErrorAction<
  typeof UserListActions.GET_USER_LIST_OPERATOR_MORE_DATA_ERROR
>;

export type IChangeUserListOperatorTablePage = Action<typeof UserListActions.CHANGE_USER_LIST_OPERATOR_TABLE_PAGE> & {
  payload: {
    page: number;
  };
};

export type IChangeUserListOperatorTablePageSize = Action<
  typeof UserListActions.CHANGE_USER_LIST_OPERATOR_TABLE_PAGE_SIZE
> & {
  payload: {
    pageSize: number;
  };
};

// INVITE_USER_FORM
export type IInviteUserFormIsLoadingAction = Action<typeof UserListActions.INVITE_USER_FORM_IS_LOADING>;
export type IInviteUserFormIsNotLoadingAction = Action<typeof UserListActions.INVITE_USER_FORM_IS_NOT_LOADING>;

export type IGetSitesListRequestOptions = SiteServiceListOptions;
export type IGetSitesListRequestAction = Action<typeof UserListActions.GET_SITES_LIST_REQUEST> & {
  payload: SiteServiceListOptions;
};
export type IGetSitesListSuccessOptions = SiteList;
export type IGetSitesListSuccessAction = Action<typeof UserListActions.GET_SITES_LIST_SUCCESS> & {
  payload: SiteList;
};
export type IGetSitesListErrorAction = IErrorAction<typeof UserListActions.GET_SITES_LIST_ERROR>;

// ADD_OPERATOR_FORM
export type IOperatorFormIsLoadingAction = Action<typeof UserListActions.ADD_OPERATOR_FORM_IS_LOADING>;
export type IOperatorFormIsNotLoadingAction = Action<typeof UserListActions.ADD_OPERATOR_FORM_IS_NOT_LOADING>;

export type IGetOperatorRequestAction = Action<typeof UserListActions.GET_OPERATOR_REQUEST> & {
  payload: {
    id: string;
  };
};
export type IGetOperatorSuccessOptions = Optional<GetOperatorResponse>;
export type IGetOperatorSuccessAction = Action<typeof UserListActions.GET_OPERATOR_SUCCESS> & {
  payload: Optional<GetOperatorResponse>;
};
export type IGetOperatorErrorAction = IErrorAction<typeof UserListActions.GET_OPERATOR_ERROR>;

export type ISaveOperatorRequestOptions = MutationNotificationUpsertOperatorArgs;
export type ISaveOperatorRequestAction = Action<typeof UserListActions.SAVE_OPERATOR_REQUEST> & {
  payload: ISaveOperatorRequestOptions;
};
export type ISaveOperatorSuccessOptions = Optional<OperatorResponse>;
export type ISaveOperatorSuccessAction = Action<typeof UserListActions.SAVE_OPERATOR_SUCCESS> & {
  payload: Optional<OperatorResponse>;
};
export type ISaveOperatorErrorAction = IErrorAction<typeof UserListActions.SAVE_OPERATOR_ERROR>;
export type IShowUserManagementDrawerAction = Action<typeof UserListActions.SHOW_USER_MANAGEMENT_DRAWER>;
export type IHideUserManagementDrawerAction = Action<typeof UserListActions.HIDE_USER_MANAGEMENT_DRAWER>;

export type IGetMachineListWithoutImageRequestAction = Action<
  typeof UserListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_REQUEST
> & {
  payload: MachineServiceListOptions;
};

export type IGetMachineListWithoutImageSuccessOptions = MachineList;
export type IGetMachineListWithoutImageSuccessAction = Action<
  typeof UserListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_SUCCESS
> & {
  payload: MachineList;
};

export type IGetMachineListWithoutImageErrorAction = IErrorAction<
  typeof UserListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_ERROR
>;

export type IUserManagementDrawerIsLoadingAction = Action<typeof UserListActions.USER_MANAGEMENT_DRAWER_IS_LOADING>;
export type IUserManagementDrawerIsNotLoadingAction = Action<
  typeof UserListActions.USER_MANAGEMENT_DRAWER_IS_NOT_LOADING
>;
export type IGetMachineListWithoutImageRequestOptions = MachineServiceListOptions;

export class UserListActions {
  public static GET_USER_LIST_REQUEST = 'USER_LIST::GET_USER_LIST_REQUEST';
  public static GET_USER_LIST_SUCCESS = 'USER_LIST::GET_USER_LIST_SUCCESS';
  public static GET_USER_LIST_ERROR = 'USER_LIST::GET_USER_LIST_ERROR';
  public static GET_USER_LIST_LOADING = 'USER_LIST::GET_USER_LIST_LOADING';

  public static GET_USER_LIST_MORE_DATA_REQUEST = 'USER_LIST::GET_USER_LIST_MORE_DATA_REQUEST';
  public static GET_USER_LIST_MORE_DATA_SUCCESS = 'USER_LIST::GET_USER_LIST_MORE_DATA_SUCCESS';
  public static GET_USER_LIST_MORE_DATA_ERROR = 'USER_LIST::GET_USER_LIST_MORE_DATA_ERROR';
  public static CHANGE_TABLE_PAGE = 'USER_LIST::CHANGE_TABLE_PAGE';
  public static CHANGE_TABLE_PAGE_SIZE = 'USER_LIST::CHANGE_TABLE_PAGE_SIZE';
  public static GET_INVITE_USER_LIST_LOADING = 'USER_LIST::GET_INVITE_USER_LIST_LOADING';
  public static GET_INVITE_USER_LIST_IS_NOT_LOADING = 'USER_LIST::GET_INVITE_USER_LIST_IS_NOT_LOADING';

  public static GET_INVITED_USER_LIST_REQUEST = 'USER_LIST::GET_INVITED_USER_LIST_REQUEST';
  public static GET_INVITED_USER_LIST_SUCCESS = 'USER_LIST::GET_INVITED_USER_LIST_SUCCESS';
  public static GET_INVITED_USER_LIST_ERROR = 'USER_LIST::GET_INVITED_USER_LIST_ERROR';
  public static POLLING_INVITED_USER_LIST_REQUEST = 'USER_LIST::POLLING_INVITED_USER_LIST_REQUEST';
  public static GET_INVITED_USER_LIST_MORE_DATA_REQUEST = 'USER_LIST::GET_INVITED_USER_LIST_MORE_DATA_REQUEST';
  public static GET_INVITED_USER_LIST_MORE_DATA_SUCCESS = 'USER_LIST::GET_INVITED_USER_LIST_MORE_DATA_SUCCESS';
  public static GET_INVITED_USER_LIST_MORE_DATA_ERROR = 'USER_LIST::GET_INVITED_USER_LIST_MORE_DATA_ERROR';
  public static CHANGE_INVITED_USER_TABLE_PAGE = 'USER_LIST::CHANGE_INVITED_USER_TABLE_PAGE';
  public static CHANGE_INVITED_USER_TABLE_PAGE_SIZE = 'USER_LIST::CHANGE_INVITED_USER_TABLE_PAGE_SIZE';

  public static GET_USER_LIST_FILTERS_REQUEST = 'USER_LIST::GET_USER_LIST_FILTERS_REQUEST';
  public static GET_USER_LIST_FILTERS_SUCCESS = 'USER_LIST::GET_USER_LIST_FILTERS_SUCCESS';
  public static GET_USER_LIST_FILTERS_ERROR = 'USER_LIST::GET_USER_LIST_FILTERS_ERROR';

  public static SET_ACTIVE_PERIOD_FILTER = 'USER_LIST::SET_ACTIVE_PERIOD_FILTER';
  public static SET_ACTIVE_ROLE_FILTER = 'USER_LIST::SET_ACTIVE_ROLE_FILTER';

  public static SET_USER_ROLE_REQUEST = 'USER_LIST::SET_USER_ROLE_REQUEST';
  public static SET_USER_ROLE_SUCCESS = 'USER_LIST::SET_USER_ROLE_SUCCESS';
  public static SET_USER_ROLE_ERROR = 'USER_LIST::SET_USER_ROLE_ERROR';

  public static INVITE_USER_REQUEST = 'USER_LIST::INVITE_USER_REQUEST';
  public static INVITE_USER_SUCCESS = 'USER_LIST::INVITE_USER_SUCCESS';
  public static INVITE_USER_ERROR = 'USER_LIST::INVITE_USER_ERROR';

  public static GET_USER_LIST_OPERATOR_REQUEST = 'USER_LIST::GET_USER_LIST_OPERATOR_REQUEST';
  public static GET_USER_LIST_OPERATOR_SUCCESS = 'USER_LIST::GET_USER_LIST_OPERATOR_SUCCESS';
  public static GET_USER_LIST_OPERATOR_ERROR = 'USER_LIST::GET_USER_LIST_OPERATOR_ERROR';
  public static GET_USER_LIST_OPERATOR_MORE_DATA_REQUEST = 'USER_LIST::GET_USER_LIST_OPERATOR_MORE_DATA_REQUEST';
  public static GET_USER_LIST_OPERATOR_MORE_DATA_SUCCESS = 'USER_LIST::GET_USER_LIST_OPERATOR_MORE_DATA_SUCCESS';
  public static GET_USER_LIST_OPERATOR_MORE_DATA_ERROR = 'USER_LIST::GET_USER_LIST_OPERATOR_MORE_DATA_ERROR';
  public static CHANGE_USER_LIST_OPERATOR_TABLE_PAGE = 'USER_LIST::CHANGE_USER_LIST_OPERATOR_TABLE_PAGE';
  public static CHANGE_USER_LIST_OPERATOR_TABLE_PAGE_SIZE = 'USER_LIST::CHANGE_USER_LIST_OPERATOR_TABLE_PAGE_SIZE';

  public static INVITE_USER_FORM_IS_LOADING = 'INVITE_USER::INVITE_USER_FORM_IS_LOADING';
  public static INVITE_USER_FORM_IS_NOT_LOADING = 'INVITE_USER::INVITE_USER_FORM_IS_NOT_LOADING';
  public static GET_SITES_LIST_REQUEST = 'INVITE_USER::GET_SITES_LIST_REQUEST';
  public static GET_SITES_LIST_SUCCESS = 'INVITE_USER::GET_SITES_LIST_SUCCESS';
  public static GET_SITES_LIST_ERROR = 'INVITE_USER::GET_SITES_LIST_ERROR';

  public static ADD_OPERATOR_FORM_IS_LOADING = 'ADD_OPERATORS::ADD_OPERATOR_FORM_IS_LOADING';
  public static ADD_OPERATOR_FORM_IS_NOT_LOADING = 'ADD_OPERATORS::ADD_OPERATOR_FORM_IS_NOT_LOADING';
  public static GET_OPERATOR_REQUEST = 'ADD_OPERATORS::GET_OPERATOR_REQUEST';
  public static GET_OPERATOR_SUCCESS = 'ADD_OPERATORS::GET_OPERATOR_SUCCESS';
  public static GET_OPERATOR_ERROR = 'ADD_OPERATORS::GET_OPERATOR_ERROR';
  public static SAVE_OPERATOR_REQUEST = 'ADD_OPERATORS::SAVE_OPERATOR_REQUEST';
  public static SAVE_OPERATOR_SUCCESS = 'ADD_OPERATORS::SAVE_OPERATOR_SUCCESS';
  public static SAVE_OPERATOR_ERROR = 'ADD_OPERATORS::SAVE_OPERATOR_ERROR';
  public static RESET_MACHINES_STATE = 'OPERATORS_FORM::RESET_MACHINES_STATE';
  public static RESET_STATE_SAVING = 'OPERATORS_FORM::RESET_STATE_SAVING';

  public static GET_MACHINE_LIST_WITHOUT_IMAGE_REQUEST =
    'USER_MANAGEMENT_DRAWER::GET_MACHINE_LIST_WITHOUT_IMAGE_REQUEST';

  public static GET_MACHINE_LIST_WITHOUT_IMAGE_SUCCESS =
    'USER_MANAGEMENT_DRAWER::GET_MACHINE_LIST_WITHOUT_IMAGE_SUCCESS';

  public static GET_MACHINE_LIST_WITHOUT_IMAGE_ERROR = 'USER_MANAGEMENT_DRAWER::GET_MACHINE_LIST_WITHOUT_IMAGE_ERROR';

  public static SHOW_USER_MANAGEMENT_DRAWER = 'USER_MANAGEMENT_DRAWER::SHOW_USER_MANAGEMENT_DRAWER';
  public static HIDE_USER_MANAGEMENT_DRAWER = 'USER_MANAGEMENT_DRAWER::HIDE_USER_MANAGEMENT_DRAWER';
  public static USER_MANAGEMENT_DRAWER_IS_LOADING = 'USER_MANAGEMENT_DRAWER::USER_MANAGEMENT_DRAWER_IS_LOADING';
  public static USER_MANAGEMENT_DRAWER_IS_NOT_LOADING = 'USER_MANAGEMENT_DRAWER::USER_MANAGEMENT_DRAWER_IS_NOT_LOADING';

  public static RESET_STATE = 'USER_LIST::RESET_STATE';

  public static ADD_OPERATOR_FORM_RESET_STATE = 'ADD_OPERATORS::ADD_OPERATOR_FORM_RESET_STATE';
  public static getUserListRequest({
    filter,
    paginationOptions,
  }: IGetUserListRequestOptions): IGetUserListRequestAction {
    return {
      type: UserListActions.GET_USER_LIST_REQUEST,
      payload: {
        filter,
        paginationOptions,
      },
    };
  }

  public static getUserListSuccess(data: IGetUserListSuccessOptions): IGetUserListSuccessAction {
    return {
      type: UserListActions.GET_USER_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getUserListError({ error }: IActionCreatorErrorOptions): IGetUserListErrorAction {
    return {
      type: UserListActions.GET_USER_LIST_ERROR,
      error,
    };
  }

  public static getUserListMoreDataRequest({
    filter,
    paginationOptions,
  }: IGetUserListMoreDataRequestOptions): IGetUserListMoreDataRequestAction {
    return {
      type: UserListActions.GET_USER_LIST_MORE_DATA_REQUEST,
      payload: {
        filter,
        paginationOptions,
      },
    };
  }

  public static getUserListMoreDataSuccess(
    data: IGetUserListMoreDataSuccessOptions
  ): IGetUserListMoreDataSuccessAction {
    return {
      type: UserListActions.GET_USER_LIST_MORE_DATA_SUCCESS,
      payload: data,
    };
  }

  public static getUserListMoreDataError({ error }: IActionCreatorErrorOptions): IGetUserListMoreDataErrorAction {
    return {
      type: UserListActions.GET_USER_LIST_MORE_DATA_ERROR,
      error,
    };
  }

  public static changeTablePage(data: { page: number }): IChangeUserListTablePage {
    return {
      type: UserListActions.CHANGE_TABLE_PAGE,
      payload: data,
    };
  }

  public static changeTablePageSize(data: { pageSize: number }): IChangeUserListTablePageSize {
    return {
      type: UserListActions.CHANGE_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static getInvitedUserListRequest({
    filter,
    paginationOptions,
  }: IGetInvitedUserListRequestOptions): IGetInvitedUserListRequestAction {
    return {
      type: UserListActions.GET_INVITED_USER_LIST_REQUEST,
      payload: {
        filter,
        paginationOptions,
      },
    };
  }

  public static getInvitedUserListSuccess(data: IGetInvitedUserListSuccessOptions): IGetInvitedUserListSuccessAction {
    return {
      type: UserListActions.GET_INVITED_USER_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getInvitedUserListError({ error }: IActionCreatorErrorOptions): IGetInvitedUserListErrorAction {
    return {
      type: UserListActions.GET_INVITED_USER_LIST_ERROR,
      error,
    };
  }

  public static getInvitedUserListMoreDataRequest({
    filter,
    paginationOptions,
  }: IGetInvitedUserListMoreDataRequestOptions): IGetInvitedUserListMoreDataRequestAction {
    return {
      type: UserListActions.GET_INVITED_USER_LIST_MORE_DATA_REQUEST,
      payload: {
        filter,
        paginationOptions,
      },
    };
  }

  public static getInvitedUserListMoreDataSuccess(
    data: IGetInvitedUserListMoreDataSuccessOptions
  ): IGetInvitedUserListMoreDataSuccessAction {
    return {
      type: UserListActions.GET_INVITED_USER_LIST_MORE_DATA_SUCCESS,
      payload: data,
    };
  }

  public static getInvitedUserListMoreDataError({
    error,
  }: IActionCreatorErrorOptions): IGetInvitedUserListMoreDataErrorAction {
    return {
      type: UserListActions.GET_INVITED_USER_LIST_MORE_DATA_ERROR,
      error,
    };
  }

  public static changeInvitedUserTablePage(data: { page: number }): IChangeInvitedUserListTablePage {
    return {
      type: UserListActions.CHANGE_INVITED_USER_TABLE_PAGE,
      payload: data,
    };
  }

  public static changeInvitedUserTablePageSize(data: { pageSize: number }): IChangeInvitedUserListTablePageSize {
    return {
      type: UserListActions.CHANGE_INVITED_USER_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static getUserListFiltersRequest(): IGetUserListFiltersRequestAction {
    return {
      type: UserListActions.GET_USER_LIST_FILTERS_REQUEST,
    };
  }

  public static getUserListFiltersSuccess(
    availableFilters: IGetUserListFiltersSuccessOptions
  ): IGetUserListFiltersSuccessAction {
    return {
      type: UserListActions.GET_USER_LIST_FILTERS_SUCCESS,
      payload: availableFilters,
    };
  }

  public static getUserListFiltersError({ error }: IActionCreatorErrorOptions): IGetUserListFiltersErrorAction {
    return {
      type: UserListActions.GET_USER_LIST_FILTERS_ERROR,
      error,
    };
  }

  public static setActiveRoleFilter(role: Optional<string>): ISetActiveRoleFilter {
    return {
      type: UserListActions.SET_ACTIVE_ROLE_FILTER,
      payload: {
        role,
      },
    };
  }

  public static setActivePeriodFilter(period: { startDate: string; endDate: string }): ISetActivePeriodFilter {
    return {
      type: UserListActions.SET_ACTIVE_PERIOD_FILTER,
      payload: {
        period,
      },
    };
  }

  public static setUserRoleRequest(data: ISetUserRoleRequestOptions): ISetUserRoleRequestAction {
    return {
      type: UserListActions.SET_USER_ROLE_REQUEST,
      payload: data,
    };
  }

  public static setUserRoleSuccess(data: ISetUserRoleSuccessOptions): ISetUserRoleSuccessAction {
    return {
      type: UserListActions.SET_USER_ROLE_SUCCESS,
      payload: data,
    };
  }

  public static setUserRoleError({ error }: IActionCreatorErrorOptions): ISetUserRoleErrorAction {
    return {
      type: UserListActions.SET_USER_ROLE_ERROR,
      error,
    };
  }

  public static inviteUserRequest(data: IInviteUserRequestOptions): IInviteUserRequestAction {
    return {
      type: UserListActions.INVITE_USER_REQUEST,
      payload: data,
    };
  }

  public static inviteUserSuccess(data: IInviteUserSuccessOptions): IInviteUserSuccessAction {
    return {
      type: UserListActions.INVITE_USER_SUCCESS,
      payload: data,
    };
  }

  public static inviteUserError({ error }: IActionCreatorErrorOptions): IInviteUserErrorAction {
    return {
      type: UserListActions.INVITE_USER_ERROR,
      error,
    };
  }

  public static resetState(): Action<typeof UserListActions.RESET_STATE> {
    return {
      type: UserListActions.RESET_STATE,
    };
  }

  public static getUserListOperatorRequest({
    paginationOptions,
  }: IGetUserListOperatorRequestOptions): IGetUserListOperatorRequestAction {
    return {
      type: UserListActions.GET_USER_LIST_OPERATOR_REQUEST,
      payload: {
        paginationOptions,
      },
    };
  }

  public static getUserListOperatorSuccess(
    data: IGetUserListOperatorSuccessOptions
  ): IGetUserListOperatorSuccessAction {
    return {
      type: UserListActions.GET_USER_LIST_OPERATOR_SUCCESS,
      payload: data,
    };
  }

  public static getUserListOperatorError({ error }: IActionCreatorErrorOptions): IGetUserListOperatorErrorAction {
    return {
      type: UserListActions.GET_USER_LIST_OPERATOR_ERROR,
      error,
    };
  }

  public static getUserListOperatorMoreDataRequest({
    paginationOptions,
  }: IGetUserListOperatorMoreDataRequestOptions): IGetUserListOperatorMoreDataRequestAction {
    return {
      type: UserListActions.GET_USER_LIST_OPERATOR_MORE_DATA_REQUEST,
      payload: {
        paginationOptions,
      },
    };
  }

  public static getUserListOperatorMoreDataSuccess(
    data: IGetUserListOperatorMoreDataSuccessOptions
  ): IGetUserListOperatorMoreDataSuccessAction {
    return {
      type: UserListActions.GET_USER_LIST_OPERATOR_MORE_DATA_SUCCESS,
      payload: data,
    };
  }

  public static getUserListOperatorMoreDataError({
    error,
  }: IActionCreatorErrorOptions): IGetUserListOperatorMoreDataErrorAction {
    return {
      type: UserListActions.GET_USER_LIST_OPERATOR_MORE_DATA_ERROR,
      error,
    };
  }

  public static changeUserListOperatorTablePage(data: { page: number }): IChangeUserListOperatorTablePage {
    return {
      type: UserListActions.CHANGE_USER_LIST_OPERATOR_TABLE_PAGE,
      payload: data,
    };
  }

  public static changeUserListOperatorTablePageSize(data: { pageSize: number }): IChangeUserListOperatorTablePageSize {
    return {
      type: UserListActions.CHANGE_USER_LIST_OPERATOR_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  // User Management
  public static showUserManagementDrawer(): IShowUserManagementDrawerAction {
    return {
      type: UserListActions.SHOW_USER_MANAGEMENT_DRAWER,
    };
  }

  public static hideUserManagementDrawer(): IHideUserManagementDrawerAction {
    return {
      type: UserListActions.HIDE_USER_MANAGEMENT_DRAWER,
    };
  }

  public static userManagementDrawerIsLoading(): IUserManagementDrawerIsLoadingAction {
    return {
      type: UserListActions.USER_MANAGEMENT_DRAWER_IS_LOADING,
    };
  }

  public static userManagementDrawerIsNotLoading(): IUserManagementDrawerIsNotLoadingAction {
    return {
      type: UserListActions.USER_MANAGEMENT_DRAWER_IS_NOT_LOADING,
    };
  }

  public static getMachineListWithoutImageRequest({
    paginationOptions,
    filter,
    sortOptions,
  }: IGetMachineListWithoutImageRequestOptions): IGetMachineListWithoutImageRequestAction {
    return {
      type: UserListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
      },
    };
  }

  public static getMachineListWithoutImageSuccess(
    data: IGetMachineListWithoutImageSuccessOptions
  ): IGetMachineListWithoutImageSuccessAction {
    return {
      type: UserListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListWithoutImageError({
    error,
  }: IActionCreatorErrorOptions): IGetMachineListWithoutImageErrorAction {
    return {
      type: UserListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_ERROR,
      error,
    };
  }

  public static resetOperatorSaving(): Action<typeof UserListActions.RESET_STATE_SAVING> {
    return {
      type: UserListActions.RESET_STATE_SAVING,
    };
  }

  public static resetMachinesState(): Action<typeof UserListActions.RESET_MACHINES_STATE> {
    return {
      type: UserListActions.RESET_MACHINES_STATE,
    };
  }

  public static saveOperatorRequest(input: ISaveOperatorRequestOptions): ISaveOperatorRequestAction {
    return {
      type: UserListActions.SAVE_OPERATOR_REQUEST,
      payload: input,
    };
  }

  public static saveOperatorSuccess(data: ISaveOperatorSuccessOptions): ISaveOperatorSuccessAction {
    return {
      type: UserListActions.SAVE_OPERATOR_SUCCESS,
      payload: data,
    };
  }

  public static saveOperatorError({ error }: IActionCreatorErrorOptions): ISaveOperatorErrorAction {
    return {
      type: UserListActions.SAVE_OPERATOR_ERROR,
      error,
    };
  }

  public static getOperatorRequest(payload: { id: string }): IGetOperatorRequestAction {
    return {
      type: UserListActions.GET_OPERATOR_REQUEST,
      payload,
    };
  }

  public static getOperatorSuccess(data: IGetOperatorSuccessOptions): IGetOperatorSuccessAction {
    return {
      type: UserListActions.GET_OPERATOR_SUCCESS,
      payload: data,
    };
  }

  public static getOperatorError({ error }: IActionCreatorErrorOptions): IGetOperatorErrorAction {
    return {
      type: UserListActions.GET_OPERATOR_ERROR,
      error,
    };
  }

  public static addOperatorFormIsLoading(): IOperatorFormIsLoadingAction {
    return {
      type: UserListActions.ADD_OPERATOR_FORM_IS_LOADING,
    };
  }

  public static addOperatorFormIsNotLoading(): IOperatorFormIsNotLoadingAction {
    return {
      type: UserListActions.ADD_OPERATOR_FORM_IS_NOT_LOADING,
    };
  }

  public static getSitesListRequest(): IGetSitesListRequestAction {
    return {
      type: UserListActions.GET_SITES_LIST_REQUEST,
      payload: {
        paginationOptions: {
          limit: OpenSearch.MAX_RESULT_WINDOW,
        },
      },
    };
  }

  public static getSitesListSuccess(data: IGetSitesListSuccessOptions): IGetSitesListSuccessAction {
    return {
      type: UserListActions.GET_SITES_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getSitesListError({ error }: IActionCreatorErrorOptions): IGetSitesListErrorAction {
    return {
      type: UserListActions.GET_SITES_LIST_ERROR,
      error,
    };
  }

  public static InviteUserFormIsLoading(): IInviteUserFormIsLoadingAction {
    return {
      type: UserListActions.INVITE_USER_FORM_IS_LOADING,
    };
  }

  public static InviteUserFormIsNotLoading(): IInviteUserFormIsNotLoadingAction {
    return {
      type: UserListActions.INVITE_USER_FORM_IS_NOT_LOADING,
    };
  }

  public static addOperatorFormResetState(): Action<typeof UserListActions.ADD_OPERATOR_FORM_RESET_STATE> {
    return {
      type: UserListActions.ADD_OPERATOR_FORM_RESET_STATE,
    };
  }

  public static getInviteUserListLoading(): Action<typeof UserListActions.GET_INVITE_USER_LIST_LOADING> {
    return {
      type: UserListActions.GET_INVITE_USER_LIST_LOADING,
    };
  }

  public static getInviteUserListIsNotLoading(): Action<typeof UserListActions.GET_INVITE_USER_LIST_IS_NOT_LOADING> {
    return {
      type: UserListActions.GET_INVITE_USER_LIST_IS_NOT_LOADING,
    };
  }

  public static getUserListLoading(): Action<typeof UserListActions.GET_USER_LIST_LOADING> {
    return {
      type: UserListActions.GET_USER_LIST_LOADING,
    };
  }

  public static pollingInvitedUserListRequest({
    email,
    operation,
  }: {
    email: string;
    operation: PollInviteUserOperation;
  }): IPollingInvitedUserListRequestAction {
    return {
      type: UserListActions.POLLING_INVITED_USER_LIST_REQUEST,
      payload: {
        email,
        operation,
      },
    };
  }
}
