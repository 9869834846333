import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { call, getContext, put, retry, takeLatest } from 'typed-redux-saga';
import { IDependencies } from '../../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  PollInviteUserOperation,
  UserInviteResponse,
  UserListAvailableFilters,
  UserListResponse,
  UserUpdateResponse,
} from '../../interfaces/User.types';
import { GetOperatorResponse, NotificationOperatorsResponse, OperatorResponse } from '../../interfaces/Operator.types';
import {
  IGetUserListErrorAction,
  IGetUserListFiltersErrorAction,
  IGetUserListFiltersSuccessAction,
  IGetUserListRequestAction,
  IGetUserListSuccessAction,
  IGetInvitedUserListErrorAction,
  IGetInvitedUserListRequestAction,
  IGetInvitedUserListSuccessAction,
  ISetUserRoleErrorAction,
  ISetUserRoleRequestAction,
  ISetUserRoleSuccessAction,
  UserListActions,
  IInviteUserRequestAction,
  IInviteUserSuccessAction,
  IInviteUserErrorAction,
  IGetUserListOperatorErrorAction,
  IGetUserListOperatorRequestAction,
  IGetUserListOperatorSuccessAction,
  IGetUserListMoreDataErrorAction,
  IGetUserListMoreDataRequestAction,
  IGetUserListMoreDataSuccessAction,
  IGetUserListOperatorMoreDataErrorAction,
  IGetUserListOperatorMoreDataRequestAction,
  IGetUserListOperatorMoreDataSuccessAction,
  IGetInvitedUserListMoreDataErrorAction,
  IGetInvitedUserListMoreDataRequestAction,
  IGetInvitedUserListMoreDataSuccessAction,
  IInviteUserFormIsLoadingAction,
  IGetMachineListWithoutImageRequestAction,
  IGetMachineListWithoutImageSuccessAction,
  IGetMachineListWithoutImageErrorAction,
  IGetOperatorRequestAction,
  IGetOperatorSuccessAction,
  IGetOperatorErrorAction,
  ISaveOperatorErrorAction,
  ISaveOperatorSuccessAction,
  ISaveOperatorRequestAction,
  IGetSitesListRequestAction,
  IGetSitesListSuccessAction,
  IOperatorFormIsLoadingAction,
  IOperatorFormIsNotLoadingAction,
  IGetSitesListErrorAction,
  IHideUserManagementDrawerAction,
  IPollingInvitedUserListRequestAction,
} from './userListActions';
import { Optional } from 'lib/types/Optional';
import { Status } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MachineList } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { SiteList } from 'app/modules/site-management/interfaces/Site.types';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { InfiniteScrollConstants, POLL_INTERVAL, POLL_MAX_RETRIES } from 'config/constants';

export function* getUserListSaga(
  action: IGetUserListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<UserListResponse>
  | PutEffect<IGetUserListSuccessAction>
  | PutEffect<IGetUserListErrorAction>,
  void,
  IDependencies
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.list, action.payload);

    yield* put(UserListActions.getUserListSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getUserListError({
        error,
      })
    );
  }
}

export function* getUserListMoreDataSaga(
  action: IGetUserListMoreDataRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<UserListResponse>
  | PutEffect<IGetUserListMoreDataSuccessAction>
  | PutEffect<IGetUserListMoreDataErrorAction>,
  void,
  IDependencies
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.list, action.payload);

    yield* put(UserListActions.getUserListMoreDataSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getUserListMoreDataError({
        error,
      })
    );
  }
}

export function* getInvitedUserListSaga(
  action: IGetInvitedUserListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<UserListResponse>
  | PutEffect<IGetInvitedUserListSuccessAction>
  | PutEffect<IGetInvitedUserListErrorAction>,
  void,
  IDependencies
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.list, action.payload);

    yield* put(UserListActions.getInvitedUserListSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getInvitedUserListError({
        error,
      })
    );
  }
}

export function* getInvitedUserListMoreDataSaga(
  action: IGetInvitedUserListMoreDataRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<UserListResponse>
  | PutEffect<IGetInvitedUserListMoreDataSuccessAction>
  | PutEffect<IGetInvitedUserListMoreDataErrorAction>,
  void,
  IDependencies
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.list, action.payload);

    yield* put(UserListActions.getInvitedUserListMoreDataSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getInvitedUserListMoreDataError({
        error,
      })
    );
  }
}

export function* getUserListFiltersSaga(): Generator<
  | GetContextEffect
  | CallEffect<UserListAvailableFilters>
  | PutEffect<IGetUserListFiltersSuccessAction>
  | PutEffect<IGetUserListFiltersErrorAction>,
  void,
  IDependencies
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.availableFilters);

    yield* put(UserListActions.getUserListFiltersSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getUserListFiltersError({
        error,
      })
    );
  }
}

export function* setUserRoleSaga(
  action: ISetUserRoleRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<UserUpdateResponse>>
  | PutEffect<ISetUserRoleSuccessAction>
  | PutEffect<ISetUserRoleErrorAction>,
  void,
  IDependencies
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.update, action.payload);

    yield* put(UserListActions.setUserRoleSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.setUserRoleError({
        error,
      })
    );
  }
}

export function* inviteUserSaga(
  action: IInviteUserRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<UserInviteResponse>>
  | CallEffect<void>
  | PutEffect<IPollingInvitedUserListRequestAction>
  | PutEffect<IInviteUserFormIsLoadingAction>
  | PutEffect<IInviteUserSuccessAction>
  | PutEffect<IInviteUserErrorAction>,
  void,
  IDependencies
> {
  const { userService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(UserListActions.InviteUserFormIsLoading());

    const response = yield* call(userService.invite, action.payload);

    if (response?.userInvite?.data) {
      yield* call(toastService.success, {
        message: t('userList.inviteUserForm.toast.success.message'),
        description: t('userList.inviteUserForm.toast.success.description'),
        iconName: 'letter-unread',
      });
      yield* put(UserListActions.inviteUserSuccess(response));

      // Call Polling for Invited User List
      yield* put(
        UserListActions.pollingInvitedUserListRequest({
          email: action.payload.input.email,
          operation: PollInviteUserOperation.INVITED,
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield* put(
      UserListActions.inviteUserError({
        error,
      })
    );
  } finally {
    yield* put(UserListActions.InviteUserFormIsNotLoading());
    yield* put(UserListActions.hideUserManagementDrawer());
  }
}

export function* getUserListOperatorSaga(
  action: IGetUserListOperatorRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<NotificationOperatorsResponse>>
  | PutEffect<IGetUserListOperatorSuccessAction>
  | PutEffect<IGetUserListOperatorErrorAction>,
  void,
  IDependencies
> {
  const { operatorService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(operatorService.list, action.payload);

    yield* put(UserListActions.getUserListOperatorSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getUserListOperatorError({
        error,
      })
    );
  }
}

export function* getUserListOperatorMoreDataSaga(
  action: IGetUserListOperatorMoreDataRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<NotificationOperatorsResponse>>
  | PutEffect<IGetUserListOperatorMoreDataSuccessAction>
  | PutEffect<IGetUserListOperatorMoreDataErrorAction>,
  void,
  IDependencies
> {
  const { operatorService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(operatorService.list, action.payload);

    yield* put(UserListActions.getUserListOperatorMoreDataSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getUserListOperatorMoreDataError({
        error,
      })
    );
  }
}

export function* getMachineListWithoutImageSaga(
  action: IGetMachineListWithoutImageRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<MachineList>
  | PutEffect<IGetMachineListWithoutImageSuccessAction>
  | PutEffect<IGetMachineListWithoutImageErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.list, action.payload);

    yield* put(UserListActions.getMachineListWithoutImageSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getMachineListWithoutImageError({
        error,
      })
    );
  }
}

export function* getSitesListSaga(
  action: IGetSitesListRequestAction
): Generator<
  GetContextEffect | CallEffect<SiteList> | PutEffect<IGetSitesListSuccessAction> | PutEffect<IGetSitesListErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(siteService.listForSelect, action.payload);

    yield* put(UserListActions.getSitesListSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getSitesListError({
        error,
      })
    );
  }
}

export function* saveOperatorSaga(
  action: ISaveOperatorRequestAction
): Generator<
  | GetContextEffect
  | PutEffect<IOperatorFormIsLoadingAction>
  | CallEffect<Optional<OperatorResponse> | void>
  | PutEffect<ISaveOperatorSuccessAction>
  | PutEffect<IGetUserListOperatorRequestAction>
  | PutEffect<ISaveOperatorErrorAction>
  | PutEffect<IHideUserManagementDrawerAction>
  | PutEffect<IOperatorFormIsNotLoadingAction>,
  void,
  IDependencies
> {
  const { operatorService, toastService, t } = yield* getContext<IDependencies>('dependencies');
  try {
    yield* put(UserListActions.addOperatorFormIsLoading());

    const response = yield* call(operatorService.upsert, action.payload);

    if (response?.notificationUpsertOperator?.data) {
      yield* put(UserListActions.saveOperatorSuccess(response));

      yield* put(
        UserListActions.getUserListOperatorRequest({
          paginationOptions: {
            limit: InfiniteScrollConstants.MAX_ITEMS,
            paginationToken: '',
          },
        })
      );

      if (action.payload.input?.id) {
        yield* put(
          UserListActions.getOperatorRequest({
            id: action.payload.input?.id as string,
          })
        );
      }

      yield* call(toastService.success, {
        message: t('userList.operatorForm.toast.success.message'),
        description: t('userList.operatorForm.toast.success.description'),
      });
    }
  } catch (error) {
    console.error(error);
    UserListActions.saveOperatorError({
      error,
    });
    yield* call(toastService.error, {
      message: t('userList.operatorForm.toast.error.message'),
    });
  } finally {
    yield* put(UserListActions.addOperatorFormIsNotLoading());
    yield* put(UserListActions.hideUserManagementDrawer());
    yield* put(DrawersActions.hideOperatorDetailsDrawer());
  }
}

function* getOperatorSaga(
  action: IGetOperatorRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<GetOperatorResponse> | void>
  | PutEffect<IGetOperatorSuccessAction>
  | PutEffect<IGetOperatorErrorAction>,
  void,
  IDependencies
> {
  try {
    const { operatorService } = yield* getContext<IDependencies>('dependencies');
    const response = yield* call(operatorService.get, action.payload.id || '');
    yield* put(UserListActions.getOperatorSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getOperatorError({
        error,
      })
    );
  }
}

function* pollingInvitedUserList(
  action: IPollingInvitedUserListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<UserListResponse>
  | PutEffect<IGetInvitedUserListSuccessAction>
  | PutEffect<IGetInvitedUserListErrorAction>,
  void,
  IDependencies
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');
  const { email, operation } = action.payload;

  const getInvitedUserListAfterMutation = async (): Promise<UserListResponse> => {
    let predicate: () => boolean;
    const payload = {
      paginationOptions: { limit: InfiniteScrollConstants.MAX_ITEMS },
      filter: {
        status: Status.Invited,
      },
    };
    const newList: UserListResponse = await userService.list(payload);
    switch (operation) {
      case PollInviteUserOperation.INVITED: {
        predicate = (): boolean => !!newList.users.data.find(u => u.email === email);
        break;
      }
      case PollInviteUserOperation.DELETED: {
        predicate = (): boolean => !newList.users.data.find(u => u.email === email);
        break;
      }
      default: {
        throw new Error(`Invalid operation: ${operation}`);
      }
    }

    if (!predicate()) {
      throw new Error('List Customer not updated');
    }

    return newList;
  };

  try {
    const response: UserListResponse = yield* retry(POLL_MAX_RETRIES, POLL_INTERVAL, getInvitedUserListAfterMutation);
    yield* put(UserListActions.getInvitedUserListSuccess(response));
  } catch (error) {
    yield* put(
      UserListActions.getInvitedUserListError({
        error,
      })
    );
  }
}

export function* userListSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(UserListActions.GET_USER_LIST_REQUEST, getUserListSaga);
  yield* takeLatest(UserListActions.GET_USER_LIST_MORE_DATA_REQUEST, getUserListMoreDataSaga);
  yield* takeLatest(UserListActions.GET_INVITED_USER_LIST_REQUEST, getInvitedUserListSaga);
  yield* takeLatest(UserListActions.GET_INVITED_USER_LIST_MORE_DATA_REQUEST, getInvitedUserListMoreDataSaga);
  yield* takeLatest(UserListActions.GET_USER_LIST_FILTERS_REQUEST, getUserListFiltersSaga);
  yield* takeLatest(UserListActions.SET_USER_ROLE_REQUEST, setUserRoleSaga);
  yield* takeLatest(UserListActions.INVITE_USER_REQUEST, inviteUserSaga);
  yield* takeLatest(UserListActions.GET_USER_LIST_OPERATOR_REQUEST, getUserListOperatorSaga);
  yield* takeLatest(UserListActions.GET_USER_LIST_OPERATOR_MORE_DATA_REQUEST, getUserListOperatorMoreDataSaga);
  yield* takeLatest(UserListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_REQUEST, getMachineListWithoutImageSaga);
  yield* takeLatest(UserListActions.GET_SITES_LIST_REQUEST, getSitesListSaga);
  yield* takeLatest(UserListActions.SAVE_OPERATOR_REQUEST, saveOperatorSaga);
  yield* takeLatest(UserListActions.GET_OPERATOR_REQUEST, getOperatorSaga);
  yield* takeLatest(UserListActions.POLLING_INVITED_USER_LIST_REQUEST, pollingInvitedUserList);
}
